const algoliasearch = require('algoliasearch/lite');
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'

const table_prefix = algolia_search_object.table_prefix;
const indexName = table_prefix + 'searchable_posts';
const searchClient = algoliasearch(algolia_search_object.algolia_id_and_key['application_id'], algolia_search_object.algolia_id_and_key['search_only_api_key']);

autocomplete({
    container: '#autocomplete',
    placeholder: 'Etsi tuotteita',
    // autoFocus: true,
    detachedMediaQuery: '',
    // debug: true,
    translations: {
        detachedCancelButtonText: 'Sulje'
    },
    getSources({ query }) {
      return [
        {
          sourceId: 'products',
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: indexName,
                  query,
                  params: {
                    hitsPerPage: 5,
                    // attributesToSnippet: ['name:10'],
                    attributesToSnippet: ['name:20', 'content:15'],
                    snippetEllipsisText: '…',
                  },
                },
              ],
            });
          },
          getItemUrl({ item }) {
            return item.url;
          },
          templates: {
            item({ item, components, html }) {
              return html`
            <a class="aa-link" href="${item.url}">
              <div class="aa-ItemWrapper">
                <div class="aa-ItemContent">
                  <div class="aa-ItemIcon aa-ItemIcon--alignTop">
                    <div
                      class="aa-thumbnail"
                      style="background-image:url(${item.images.medium ? item.images.medium.url : '/wp-content/plugins/wv-algolia/img/thumbnail-placeholder.png'})">
                    </div>
                  </div>
                  <div class="aa-ItemContentBody">
                    <div class="aa-ItemContentTitle">
                      ${components.Highlight({
                        hit: item,
                        attribute: 'title',
                      })}
                    </div>
                    <div class="aa-ItemContentDescription">
                      ${components.Snippet({
                        hit: item,
                        attribute: 'content',
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </a>`;
            },
          },
        },
      ];
    },
    onSubmit({ state }) {
        window.location = "/tuotteet/?query=" + state.query;
    },
  });